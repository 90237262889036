<template>
  <div class="wrapper">
    <div class="wrapper__container">
      <jublia-header />
      <div class="container">
        <section id="ressorce" class="mb-8">
          <jublia-h3 class="DINBold text-jublia-bluest mb-4"> {{ $t('home.ress.title') }}</jublia-h3> <br/> 
<p class="title m DINBold mt-4"> {{ $t('home.ress.subtitle') }}</p>
<br />
<jublia-p class="" v-html="$t('home.ress.text')"/>
<!--<drop>
    <p class="title m DINBold mt-4" slot=title > {{ $t('home.ress.subtitle') }}</p>
<br />
<br />
   <jublia-p slot=content v-html="$t('home.ress.text')"/>
</drop>-->

 <p class="title m DINBold mb-4" >{{$t('home.ress.click')}}</p>
<div class="w-full  md:mx-auto md:w-4/5 lg:w-2/3 sm:w-4/5 sm:mx-auto" >
                <a  v-if="getLocale() == 'fr'" href=" https://www.bauschhealthlink.ca/wp-content/uploads/2021/01/Jublia-Brochure-patient.pdf"  target="_blank">
                   
                <jublia-button
              bgpurple
              style="background:#006699 !important;"
              class="w-full flex justify-between items-center  bg-jublia-bluest"
              :blu= true
            >
              <div  style=" font-size:25px !important; line-height: 30px !important; font-weight:400 !important;" class="text-xl DINBold flex-grow">
              {{$t('home.ress.button')}}
              </div>
              <img
                src="@/assets/icons/down.svg"
                alt="next button"
                width="15"
                height="18"
              />
            </jublia-button></a>
              <a  v-if="getLocale() == 'en'" href=" https://www.bauschhealthlink.ca/wp-content/uploads/2021/01/Jublia-Patient-Brochure.pdf" target="_blank">
                    
                <jublia-button
              bgpurple
              style="background:#006699 !important;"
              class="w-full flex justify-between items-center bg-jublia-bluest DINBold"
              :blu= true
            >
             
              <div  style="line-height: 30px !important; " class="text-xl DINBold  flex-grow">
              {{$t('home.ress.button')}}
              </div>
              <img
                src="@/assets/icons/down.svg"
                alt="next button"
                width="15"
                height="18"
              />
            </jublia-button></a>
          </div>
          <br />
          <div  class="w-full  mt-8 md:mx-auto md:w-4/5 lg:w-2/3"  @click="toggleMenu">
            <jublia-button
            
              bgpurple
              style="b"
              class="w-full flex justify-between items-center DINBold"
            >
              <div class="text-xl font-bold  DINBold">
                {{ $t('common.menu') }}
              </div>
            
            </jublia-button>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import JubliaHeader from '@/components/header/jublia-header'
import JubliaH1 from '@/components/text/jublia-h1'
import JubliaP from '@/components/text/jublia-p'
import JubliaButton from '@/components/button/jublia-button'
//import Drop from '~/components/dropdown/drop.vue'
  import { useI18n } from "vue3-i18n";

export default {
  components: {
    
    'jublia-button':JubliaButton,
    'jublia-header':JubliaHeader,
    'jublia-h1': JubliaH1,
    'jublia-p':JubliaP
   
  },

 data: () => ({
    nbMonths: 0,
   
  }),
 
getLocaleInUrl() {
      const lang = this.$route.fullPath.split('=').pop()
      if (
        this.$route.fullPath.split('=').pop() === 'fr' ||
        this.$route.fullPath.split('=').pop() === 'en'
      ) {
        this.setLocale(lang)
      }
    },
     methods: {
   ...mapMutations('menu',['toggleMenu']),
   getLocale() {
      console.log(this.$i18n.locale)
      return this.$i18n.getLocale()
    }
     }
    }
</script>
<style  scoped>
.m{
margin-left: 0 !important;

}
p>ul >.li{
  text-align:left!important;
  line-height:225% !important;
}
a {
    color: #666666 !important;
   
}
.container {

  @media all and (min-width: 768px) {
   
   min-height: 35rem;
  }
}

</style>




